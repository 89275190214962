import {
    BIS_IS_SENT,
    isInStartedFlowsCookie,
} from "../misc/localStorageKeys";
import ExternalRedirect from "../components/ExternalRedirect";
import {BIS_CALLBACK_URL, FIS_INIT_CONNECTION_URL} from "../misc/api";
import {useEffect, useMemo, useState} from "react";
import {get, post} from "../misc/axios";
import {logInfo, logError} from "../misc/logger";
import Cookies from 'js-cookie'
import {useSearchParams} from "react-router-dom";
import {InitConnection} from "../types/InitConnection";
import debounce from "lodash.debounce";

export default function DbRedirectPage() {
    const sendBisCallback = useMemo(() =>
            debounce(
                (providerCode, integrationSource) => post(
                    BIS_CALLBACK_URL,
                    {
                        "providerCode": providerCode,
                        "integrationSource": integrationSource,
                        "redirectUrl": window.location.href
                    }
                ).then(() => {
                    console.log("BIS callback sent");
                }).catch((e) => {
                    console.error(e);
                }), 5000, {leading: true, trailing: false})

        , []);

    const [searchParams] = useSearchParams();
    const state = searchParams.get('state')

    const code = searchParams.get("code");
    const authorizationCode = searchParams.get("authorization_code");
    const errorParam = searchParams.get("error");

    const urlError = code || authorizationCode ? "" : errorParam ? errorParam : "authorization_code_is_not_present";

    const [data, setData] = useState<InitConnection | null>(null);
    const [loading, setLoading] = useState(true);
    const [fetchError, setFetchError] = useState<any>(null);
    const [flowStarted, setFlowStarted] = useState<undefined | boolean>(undefined);

    if (state === null) {
        return null
    }

    // If the flow is already started, we don't want to start it again
    if (isInStartedFlowsCookie(state)) {
        return null
    }

    useEffect(() => {

        setFlowStarted(isInStartedFlowsCookie(state))

        const fetchData = async () => {
            try {
                const response = await get<InitConnection>(FIS_INIT_CONNECTION_URL + '/' + state);
                setData(response.data);
                logInfo("User.RecievedFisData", "", data?.clientId, data?.connectionId)
            } catch (error) {
                setFetchError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [])

    //const requestWasSent = useRef<Boolean>(false);

    const appUrl = ((url) => url ? new URL(url) : null)(data?.appUrl);

    if (appUrl && urlError) {
        appUrl.searchParams.append("error", urlError);
    }

    useEffect(() => {

        if (urlError) {
            console.error("Invalid URL parameters. Callback not sent.");
            logError("System.CallbackUrlError", "Invalid URL parameters. Callback not sent", null, null);
            return;
        }

        logInfo("System.BISCallbackUrlCalled",
            data ? JSON.stringify(data) : "no data",
            null,
            null
        )

        if (data == null) {
            return;
        }

        const bisIsSent = Cookies.get(BIS_IS_SENT);

        //  if (!requestWasSent.current) {
        if (bisIsSent == undefined || bisIsSent == "false") {
            if (data.providerCode && data.integrationSource) {
                //requestWasSent.current = true;
                Cookies.set(BIS_IS_SENT, "true")

                sendBisCallback(data.providerCode, data.integrationSource)

                logInfo("System.CallbackUrlCalled",
                    "",
                    data.clientId,
                    data.connectionId
                )
            } else {
                console.error("Missing providerCode or integrationSource");
            }
        }
        // }

    }, [data?.state])

    if (flowStarted === undefined || flowStarted) {
        console.log("flowsStarted: " + flowStarted)
        return null
    }

    if (data == null || !appUrl) {
        return <div></div>;
    }

    return (
        <div className="App">
            <header className="App-header">
                <ExternalRedirect
                    url={appUrl.href}
                    action={"User.RedirectedToApp"}
                    clientId={data.clientId}
                    connectionId={data.connectionId}
                    clearLocalStorage={true}
                    state={null}
                />
            </header>
        </div>
    )
}
