import {
    APP_URL,
} from "../misc/localStorageKeys";
import Cookies from 'js-cookie'
import {useSearchParams} from "react-router-dom";
import CookiesRedirectPage from "../components/CookiesRedirectPage";
import DbRedirectPage from "../components/DbRedirectPage";
import {isCookieRedirect} from "../misc/Utils";

export default function ReturnRedirectPage() {
    const [searchParams] = useSearchParams();
    const state = searchParams.get('state')
    const cookieAppUrl = Cookies.get(APP_URL);

    if (!state) {
        console.error("State is null")
        return null
    }

    if (isCookieRedirect(cookieAppUrl)) {
        return <CookiesRedirectPage />
    } else {
        return <DbRedirectPage />
    }
}
