import {LOG_URL} from "./api";
import {post} from "./axios";


export function logError(action: Action, message: string = '', clientId: string | null | undefined, connection: string | null | undefined) {
    log('ERROR', action, message, clientId, connection);
}

export function logInfo(action: Action, message: string = '', clientId: string | null | undefined, connection: string | null | undefined): void {
    log('INFO', action, message, clientId, connection);
}

function log(level: Level, action: Action, message: string, clientId: string | null | undefined, connectionId: string | null | undefined): void {
    const state = new URLSearchParams(window.location.search).get('state');
    post<Log>(LOG_URL, {
        level: level,
        message: message,
        action: action,
        correlationId: null,
        clientId: clientId,
        connectionId: connectionId,
        state: state
    }).then(() => {
    }).catch((e) => {
        console.error(`Error greylog logging - ${level}`, e);
    })


    // try {
    //     useAxios<any, Log>({
    //         url: LOG_URL,
    //         //baseURL: 'http://localhost:8080',
    //         method: 'POST',
    //         data: {
    //             level: level,
    //             message: message,
    //             action: action,
    //             correlationId: null,
    //             clientId: localStorage.getItem(CLIENT_ID),
    //             connectionId: localStorage.getItem(CONNECTION_ID),
    //         }
    //     })
    // } catch (e) {
    //     console.error(`Error greylog logging - ${level}`, e);
    // }
}

type Log = {
    level: string;
    message: string;
    action: UserAction | SystemAction;
    correlationId: string | null | undefined;
    clientId: string | null | undefined;
    connectionId: string | null | undefined;
    state: string | null | undefined;
}

type Level = 'INFO' | 'ERROR' | 'WARN' | 'DEBUG';
type Action = UserAction | SystemAction;
export type UserAction = 'User.Started' | 'User.RedirectedToBank' | 'User.ReturnedFromBank' | 'User.RedirectedToApp' | 'User.RecievedFisData'
export type SystemAction = 'System.FinishConnectionSent' | 'System.ExchangeTokenSent' | 'System.CallbackUrlCalled' | 'System.BISCallbackUrlCalled' | 'System.CallbackUrlError'
