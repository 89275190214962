import Cookies from "js-cookie";

const prefix = 'fis.';
export const AUTHORISATION_URL = prefix + 'authorisationUrl';
export const APP_URL = prefix + 'appUrl';
export const INTEGRATION_SOURCE = prefix + 'integrationSource';
export const PROVIDER_CODE = prefix + 'providerCode';
export const CLIENT_ID = prefix + 'clientId';
export const CONNECTION_ID = prefix + 'connectionId';
export const BIS_IS_SENT = prefix + 'bisIsSent';
export const FLOWS_STARTED = prefix + 'flowsStarted';
export const ALL_LOCAL_STORAGE_KEYS = [
    AUTHORISATION_URL,
    APP_URL,
    INTEGRATION_SOURCE,
    PROVIDER_CODE,
    CLIENT_ID,
    CONNECTION_ID,
    BIS_IS_SENT,
];

export const addToStartedFlowsCookie =  (state: string) => {
    const flowsStarted = Cookies.get(FLOWS_STARTED)
    if (flowsStarted === undefined) {
        Cookies.set(FLOWS_STARTED, JSON.stringify([state]))
    } else {
        const flowsStartedArray = JSON.parse(flowsStarted)
        if (!flowsStartedArray.includes(state)) {
            flowsStartedArray.push(state)
            Cookies.set(FLOWS_STARTED, JSON.stringify(flowsStartedArray))
        }
    }
}
export const isInStartedFlowsCookie = (state: string) => {
    const flowsStarted = Cookies.get(FLOWS_STARTED)
    if (flowsStarted === undefined) {
        return false
    } else {
        const flowsStartedArray = JSON.parse(flowsStarted)
        return flowsStartedArray.includes(state)
    }
}
