import {useParams, useSearchParams} from 'react-router-dom';
import {
    ALL_LOCAL_STORAGE_KEYS,
    APP_URL,
    AUTHORISATION_URL,
    CLIENT_ID,
    CONNECTION_ID,
    INTEGRATION_SOURCE, isInStartedFlowsCookie,
    PROVIDER_CODE
} from "../misc/localStorageKeys";
import ExternalRedirect from "../components/ExternalRedirect";
import {checkAndFixUrl} from "../misc/Utils";
import {logInfo} from "../misc/logger";
import {SOURCES} from "../misc/Source";
import {useEffect, useState} from "react";
import {detectIncognito} from "detectincognitojs";
import Cookies from 'js-cookie'

export default function BankRedirectPage() {
    const {integrationSource} = useParams();
    const {providerCode} = useParams();
    const [searchParams] = useSearchParams();
    let authorisationUrl = searchParams.get('authorisationUrl')
    let appUrl = searchParams.get('appUrl')
    const clientId = searchParams.get('clientId')
    const connectionId = searchParams.get('connectionId')
    const state = searchParams.get('state')

    const cookies = Cookies.get()
    if (cookies != undefined && Object.keys(cookies).length > 0) {
        ALL_LOCAL_STORAGE_KEYS.forEach((key) => {
            Cookies.remove(key)
        })
    }

    if (integrationSource) {
        Cookies.set(INTEGRATION_SOURCE, integrationSource)
    }

    if (providerCode) {
        Cookies.set(PROVIDER_CODE, providerCode)
    }

    if (authorisationUrl) {
        authorisationUrl = checkAndFixUrl(authorisationUrl);
        Cookies.set(AUTHORISATION_URL, authorisationUrl)
    }

    if (appUrl) {
        appUrl = checkAndFixUrl(appUrl);
        Cookies.set(APP_URL, appUrl)
    }

    if (clientId) {
        Cookies.set(CLIENT_ID, clientId)
    }

    if (connectionId) {
        Cookies.set(CONNECTION_ID, connectionId)
    }

    useEffect(() => {
        logInfo('User.Started', '', clientId, connectionId)
    }, [])

    const [isPrivate, setIsPrivate] = useState<undefined | boolean>(undefined);
    const [flowStarted, setFlowStarted] = useState<undefined | boolean>(undefined);

    useEffect(() => {
        if (state) {
            setFlowStarted(isInStartedFlowsCookie(state))
        }

        detectIncognito().then((result) => {
            setIsPrivate(result.isPrivate);
            console.log("browserName: " + result.browserName, "isPrivate: " + result.isPrivate);
        });
    }, [])

    if (flowStarted === undefined || flowStarted) {
        console.log("flowsStarted: " + flowStarted)
        return null
    }

    if (isPrivate === undefined) {
        console.log("isPrivate is undefined")
        return null
    }

    if (isPrivate) {
        return (
            <div className="App">
                <header className="App-header">
                    <p>
                        {"Private browsing mode detected. Please disable private browsing mode to continue."}
                    </p>
                </header>
            </div>
        )
    }

    return (
        <div className="App">
            <header className="App-header">

                {integrationSource && !SOURCES.includes(integrationSource) && <p>
                    {"Unknown param source: " + integrationSource}
                </p>}
                {!authorisationUrl && <p>
                    {"Param 'authorisationUrl' is missing in URL!"}
                </p>}
                {!clientId && <p>
                    {"Param 'clientId' is missing in URL!"}
                </p>}
                {!connectionId && <p>
                    {"Param 'connectionId' is missing in URL!"}
                </p>}
                {!appUrl && <p>
                    {"Param 'appUrl' is missing in URL!"}
                </p>}
                {integrationSource && SOURCES.includes(integrationSource) && authorisationUrl && appUrl && clientId && connectionId && <>
                    <ExternalRedirect
                        url={authorisationUrl}
                        action={'User.RedirectedToBank'}
                        clientId={clientId}
                        connectionId={connectionId}
                        clearLocalStorage={false}
                        state={state}
                    />
                </>}
            </header>
        </div>
    )
}
