import {useEffect} from "react";
import {logInfo, UserAction} from "../misc/logger";
import {
    addToStartedFlowsCookie,
    ALL_LOCAL_STORAGE_KEYS,
    APP_URL,
    FLOWS_STARTED,
    INTEGRATION_SOURCE
} from "../misc/localStorageKeys";
import Cookies from "js-cookie";

export default function ExternalRedirect(props: { url: string, action: UserAction, clientId: string | null | undefined, connectionId: string | null | undefined, clearLocalStorage: boolean, state: string | null }) {
    useEffect(() => {
        logInfo(props.action, '', props.clientId, props.connectionId)
    }, [])
    const {url, state} = props;


    function redirect(withHistory = false) {
        if (props.clearLocalStorage) {
            ALL_LOCAL_STORAGE_KEYS.forEach((key) => {
                Cookies.remove(key);
            })
        }
        if (state !== null) {
            addToStartedFlowsCookie(state)
        }

        if (withHistory) {
            window.location.href = url;
        } else {
            window.location.replace(url);
        }
        return null;
    }

    useEffect(() => {
        setTimeout(() => redirect(false), 100);
    }, [])

    const appUrl = Cookies.get(APP_URL);
    const integrationSource = Cookies.get(INTEGRATION_SOURCE);
    const startedFlows = Cookies.get(FLOWS_STARTED);

    return (
        <>
            {/*<p>Cookies</p>*/}
            {/*<p>AppUrl: {appUrl}</p>*/}
            {/*<p>Integration source: {integrationSource}</p>*/}
            {/*<p>Started flows: {startedFlows}</p>*/}

            {/*<button onClick={() => redirect(false)}>*/}
            {/*    Redirect*/}
            {/*</button>*/}
        </>
    )
}
