import './App.css'
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider, useSearchParams
} from "react-router-dom";
import BankRedirectPage from "./pages/BankRedirectPage";
import ReturnRedirectPage from "./pages/ReturnRedirectPage";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {BB_URL} from "./misc/api";


// Sentry.init({
//     dsn: "https://21b76568ecb14ffeae79ef6e6c7e26e2@o71123.ingest.sentry.io/6366445",
//     integrations: [new BrowserTracing()],
//
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 0.1,
// });

const router = createBrowserRouter(
    createRoutesFromElements([
            <Route path="/:integrationSource/:providerCode" element={<BankRedirectPage/>}/>,
            <Route path="/" element={<ReturnRedirectPage/>}/>
        ]
    )
);


function App() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('state') == null) {
        window.location.href = BB_URL;
    }
    return (
        <RouterProvider router={router}/>
    )
}

export default App
