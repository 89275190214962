export function checkAndFixUrl(url: string): string {
    if (!(url.startsWith('http://') || url.startsWith('https://'))) {
        return 'https://' + url;
    }
    return url;
}

// We need to check, if url from cookie is set, if not we use the db approach
export function isCookieRedirect (url: string | undefined): boolean {
    return url !== undefined;
}
