import axios, {AxiosError, AxiosResponse, RawAxiosRequestConfig} from "axios";
import {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {BB_URL} from "./api";


export type AxiosResult<T> = {
    data: T | null;
    error: AxiosError | unknown;
    loading: boolean;
    response: AxiosResponse<T> | null;
    cancelRequest: () => void;

}
export const useAxios = <T, D = any>(axiosParams: RawAxiosRequestConfig<D>): AxiosResult<T> => {
    const [data, setData] = useState<T | null>(null);
    const [response, setResponse] = useState<AxiosResponse | null>(null);
    const [error, setError] = useState<AxiosError | unknown>();
    const [loading, setLoading] = useState(true);
    const controllerRef = useRef(new AbortController());
    const cancelRequest = () => {
        controllerRef.current.abort();
    };

    useEffect(() => {
        const fetchData = async (params: RawAxiosRequestConfig<D>) => {
            try {
                const axiosResponse = await axios.request<T>({
                    ...params,
                    signal: controllerRef.current.signal,
                });
                setData(axiosResponse.data);
                setResponse(axiosResponse);
            } catch (err: AxiosError | unknown) {
                if (axios.isAxiosError(err)) {
                    setError(err);
                    // this will narrow the type :)
                    // Access to config, request, and response
                    throw err;
                } else {
                    setError(err);
                    // Just a stock error
                    throw err;
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData(axiosParams);
    }, []);

    return {cancelRequest, data, response, error, loading};
}
const axiosOptions: RawAxiosRequestConfig = {
    headers: {},
    params: {},
};
export const post = async <D>(url: string, data?: D, options: RawAxiosRequestConfig = axiosOptions) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let finalOptions = {
        headers: options.headers,
        params: {
            ...options.params,
            state: urlParams.get('state')
        }
    };
    return await axios.post(url, data, finalOptions);
}

export const get = async <D>(url: string, options: RawAxiosRequestConfig = axiosOptions) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let finalOptions = {
        headers: options.headers,
        params: {
            ...options.params,
            state: urlParams.get('state')
        }
    };
    return await axios.get<D>(url, finalOptions);
}
