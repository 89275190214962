import {
    APP_URL,
    BIS_IS_SENT,
    CLIENT_ID,
    CONNECTION_ID,
    INTEGRATION_SOURCE, isInStartedFlowsCookie,
    PROVIDER_CODE
} from "../misc/localStorageKeys";
import ExternalRedirect from "../components/ExternalRedirect";
import {useEffect, useMemo, useRef} from "react";
import {logInfo, logError} from "../misc/logger";
import Cookies from 'js-cookie'
import debounce from "lodash.debounce";
import {post} from "../misc/axios";
import {BIS_CALLBACK_URL} from "../misc/api";

export default function CookiesRedirectPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const authorizationCode = urlParams.get("authorization_code");
    const errorParam = urlParams.get("error");

    const error = code || authorizationCode ? "" : errorParam ? errorParam : "authorization_code_is_not_present";

    const sendBisCallback = useMemo(() =>
            debounce(
                (providerCode, integrationSource) => post(
                    BIS_CALLBACK_URL,
                    {
                        "providerCode": providerCode,
                        "integrationSource": integrationSource,
                        "redirectUrl": window.location.href
                    }
                ).then(() => {
                    console.log("BIS callback sent");
                }).catch((e) => {
                    console.error(e);
                }), 5000, {leading: true, trailing: false})
        , []);

    const clientId = Cookies.get(CLIENT_ID);
    const connectionId = Cookies.get(CONNECTION_ID);

    useEffect(() => {
        logInfo("User.ReturnedFromBank", "", clientId, connectionId)
    }, [])

    const integrationSource = Cookies.get(INTEGRATION_SOURCE);
    const providerCode = Cookies.get(PROVIDER_CODE);
    const appUrl = ((url) => url ? new URL(url) : null)(Cookies.get(APP_URL));

    if (appUrl && error) {
        appUrl.searchParams.append("error", error);
    }

    const requestWasSent = useRef<Boolean>(false);

    useEffect(() => {
        const integrationSource = Cookies.get(INTEGRATION_SOURCE);
        const providerCode = Cookies.get(PROVIDER_CODE);
        const bisIsSent = Cookies.get(BIS_IS_SENT);

        if (error) {
            console.error("Invalid URL parameters. Callback not sent.");
            logError("System.CallbackUrlError",
               "Invalid URL parameters. Callback not sent",
                clientId,
                connectionId
            )
            return;
        }

        if (!requestWasSent.current) {
            if (bisIsSent == undefined || bisIsSent == "false") {
                if (providerCode && integrationSource) {
                    requestWasSent.current = true;
                    Cookies.set(BIS_IS_SENT, "true")

                    sendBisCallback(providerCode, integrationSource)

                    logInfo("System.CallbackUrlCalled",
                        "",
                        clientId,
                        connectionId
                    )
                } else {
                    console.error("Missing providerCode or integrationSource");
                }
            }
        }

    }, [])

    return (
        <div className="App">
            <header className="App-header">
                {!appUrl && <p>
                    {"'appUrl' is missing!"}
                </p>}
                {!integrationSource && <p>
                    {"'integrationSource' is missing!"}
                </p>}
                {!providerCode && <p>
                    {"'providerCode' is missing!"}
                </p>}
                {providerCode && integrationSource && appUrl && <>
                    <ExternalRedirect
                        url={appUrl.href}
                        action={"User.RedirectedToApp"}
                        clientId={clientId}
                        connectionId={connectionId}
                        clearLocalStorage={true}
                        state={null}
                    />
                </>}
            </header>
        </div>
    )
}
